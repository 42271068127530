import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/index.vue')
  },

  {
    path: '/login',
    component: () => import('@/views/auth/login.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/auth/register.vue')
  },
  {
    path: '/home/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/home/bookings'
      },
      {
        path: 'dashboard',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'bookings',
        component: () => import('@/views/bookings/index.vue')
      },
      {
        path: 'bookings/create',
        component: () => import('@/views/bookings/create.vue')
      },
      {
        path: 'bookings/:id',
        component: () => import('@/views/bookings/view.vue')
      },
      {
        path: 'settings',
        component: () => import('@/views/settings.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
